.navbar_admin {
    position: relative;
    background: var(--cl);
    border-right: 2px solid var(--bl);
}


.navigation {
    width: 200px;
    max-width: 280px;
    height: 100vh;
    background: var(--cl);
    color: var(--ff);
    transition: 0.5s;
    overflow: hidden;
    overflow-y: auto;
    direction: ltr;
    display: flex;
    position: relative;
}

svg.MdMenuOpen {
    position: absolute;
    font-size: 40px;
    right: 0;
    color: var(--ff);
    cursor: pointer;
    z-index: 99;
}

.navigation.active {
    width: 40px;
}

.navigation ul {
    width: 100%;
}

.navigation ul li {
    position: relative;
    width: 100%;
    cursor: pointer;
}

.navigation ul li:hover a,
.navigation ul li.hovered a {
    background: var(--ff);
    color: var(--cl);
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.navigation ul li:nth-child(1) {
    margin-bottom: 40px;
    pointer-events: none;
}

.navigation.active ul li:nth-child(1) {
    visibility: hidden;
}

.navigation ul li a {
    position: relative;
    width: 100%;
    display: flex;
    color: var(--ff);
    align-items: center;
}

.navigation ul li:hover a,
.navigation ul li.hovered a {
    color: var(--cl);
}

.navigation ul li a .icon {
    position: relative;
    display: block;
    text-align: center;
    padding: 6px;
}

.navigation ul li a .icon svg {
    font-size: 1.75rem;
}

.navigation ul li a .title {
    position: relative;
    display: block;
    padding: 0 10px;
    line-height: 40px;
    text-align: start;
    max-width: 140px;
    white-space: nowrap;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
}


.navigation ul li:last-child {
    margin-top: 40px;
    cursor: pointer;
}

.dropdown-container.hide {
    display: none;
}

.dropdown-container {
    padding-left: 15px;

    a {
        background: transparent !important;
        color: var(--ff) !important;
    }

    a.active {
        background: var(--ff) !important;
        color: var(--cl) !important;
    }
}

.dropdown-container span.title {
    font-size: 10px !important;
}

.dropdown-container span.icon {
    padding: 5px !important;
}

.dropdown-container span.icon svg {
    font-size: 15px !important;
}

.dropdown-container span.title {
    padding: 0 5px !important;
    line-height: 30px !important;
}

.dropdown-container a {
    margin: 5px 0;
}

.navigation ul li a svg.IoMdArrowDropleft {
    position: absolute;
    right: -5px;
}