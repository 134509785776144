.settings {
    display: flex;
    flex-direction: column;
    gap: 7px;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 50vh;
    align-items: center;
    justify-content: center;
}

.settings form {
    background: var(--cl);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 20px;
    min-width: 280px;
}

.settings .input {
    padding: 1rem;
    border-radius: 1rem;
    background: #e8e8e8;
    transition: 0.3s;
    width: 100%;
}

.settings .input:focus {
    outline-color: #e8e8e8;
    background: #e8e8e8;
    transition: 0.3s;
}

.settings .background_upload img {
    height: auto;
}