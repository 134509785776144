.all_championshipC_category svg.delete {
    font-size: 20px;
    color: #f00;
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

.all_championshipC_category svg.delete:hover {
    opacity: 0.7;
    transform: scale(1.3);
}

.all_championshipC_category {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin: 20px auto;
}

.all_championshipC_category .championship_category_item {
    background: var(--cc);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 20px;
}

.all_championshipC_category .championship_category_item .background_upload {
    width: 100px;
    height: 100px;
}

.all_championshipC_category .championship_category_item input.input {
    padding: 5px;
}

.all_championshipC_category .championship_category_item input.button {
    width: fit-content;
}

.submit_delete {
    display: flex;
    align-items: center;
    justify-content: space-between;
}