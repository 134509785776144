.customers {
    .item_championship {
        background: var(--cl);
        padding: 5px;
        border-radius: 10px;

        span {
            background: var(--bl);
            padding: 5px;
            border-radius: 5px;
        }
    }
}