@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

:root {
  --bg: #FBFBFB;
  --cl: #656565;
  --ff: #fff;
  --bl: #111;
  --cc: #ccc;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  font-weight: 100;
}

fieldset {
  border: 2px solid var(--cl);
  padding: 5px;
  color: var(--bl);
}

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: var(--bg);
}

::-webkit-scrollbar-thumb {
  background: var(--cl);
  border-radius: 20px;
}

img,
video {
  width: 100%;
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes animation {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

body {
  overflow-x: hidden;
  font-family: "Rubik", sans-serif;
  max-width: 1280px;
  margin: auto;
  background: var(--bl);
  color: var(--ff);
}

.button {
  font-size: 18px;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 50px;
  background: var(--cl);
  color: var(--ff);
}

.button:hover {
  opacity: 0.7;
}

legend {
  padding: 10px 20px;
  background: var(--cl);
  color: var(--ff);
}

.left_right {
  display: flex;
  flex-wrap: wrap;
  gap: 5vh;
  width: fit-content;
  padding: 5vh;
  background: var(--ff);
  border-radius: 30px;
  margin: auto;
  overflow: hidden;
  box-shadow: inset 0 0 10px var(--bl);

  .right,
  .left {
    flex: 1;
    display: flex;
    gap: 1vh;
    color: var(--bl);
    min-width: 280px;

    @media (max-width:768px) {
      min-width: 200px;
    }
  }

  .left {
    flex-direction: column;
    font-size: 85%;
    max-width: 280px;
    line-height: 20px;

    a.logo {
      width: 140px;
    }
  }

  .right {
    form {
      display: flex;
      flex-direction: column;
      gap: 2vh;
    }


    fieldset {
      border-width: 2px;
      border-style: groove;
      border-color: var(--bg);
      padding: 5px;
      border-radius: 10px;
      font-size: 70%;
    }

    textarea {
      resize: none;
      height: 130px;
    }

    input:focus,
    textarea:focus {
      background: transparent;
      border: 2px solid var(--bg);
    }

    input,
    textarea {
      max-width: 280px;
      background: var(--bg);
      padding: 10px;
      font-weight: bold;
      border-radius: 10px;
      width: 100%;
      border: 2px solid transparent;
    }
  }

  .inputBox {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  a {
    color: var(--cl);
    font-size: 10px;

    &:hover {
      opacity: 0.7;
    }
  }

  .button {
    width: fit-content;
  }
}


.Toastify__toast-container {
  z-index: 999999 !important;
  text-transform: capitalize;
}

select option:first-child {
  display: none !important;
}

.pages {
  width: 90%;
  height: 100%;
  padding: 20px 0;
  margin: auto;
}

.container_admin {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  direction: rtl;
}

section.section_admin {
  width: 100%;
  height: 100%;
  direction: ltr;
  margin: 20px auto;
  padding: 0 20px;
}


.password_eye {
  position: relative;
  width: 100%;
  height: 100%;

  input.input {
    width: 100% !important;
    max-width: 280px;
    background: var(--bg);
    padding: 10px;
    font-weight: bold;
    border-radius: 10px;
    border: 2px solid transparent;
  }

  svg {
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--cl);

    &:hover {
      opacity: 0.7;
    }
  }
}

.direction.direction_rtl .password_eye svg {
  right: auto;
  left: 5px;
}


.div_load_more {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

.background_upload {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.background_upload input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.background_upload img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.social_media_links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
}

.social_media_links a.social_media {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  transition: all 300ms ease-in-out;
}

.social_media_links a.social_media:hover {
  transform: translateY(-5px);
  opacity: 0.7;
}

.jodit-react-container {
  width: 100%;
}


.jodit-status-bar,
.jodit-ui-group.jodit-ui-group_separated_true.jodit-ui-group_group_info.jodit-ui-group_size_middle,
.jodit-ui-group.jodit-ui-group_separated_true.jodit-ui-group_group_other.jodit-ui-group_size_middle {
  display: none !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
}


.position_fixed {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  background: var(--bl);
  color: var(--ff);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 500ms ease-in-out;
  animation: animation 1s ease-in-out;

  .div_position_fixed {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }

  svg.handleClose {
    color: var(--cl);
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    right: 1%;
    top: 1%;
    z-index: 9999;

    &:hover {
      opacity: 0.7;
    }
  }
}


.verification_code {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 280px;
  text-align: center;
  align-items: center;
  background: var(--ff);
  padding: 10px;
  border-radius: 10px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  color: var(--bl);

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    input {
      width: 35px !important;
      height: 35px;
      border-radius: 5px;
      font-size: 18px;
      border: 1px solid var(--cl);
    }
  }
}

fieldset.Category {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    border: 1px solid var(--ff);
    padding: 5px;
    border-radius: 10px;
    color: var(--ff);
  }

  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
  }
}


.all_championship {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 25px;
  margin: 20px auto;

  @media (max-width: 750px) {
    justify-content: center;
  }

  .item_championship {
    flex: 280px;
    max-width: 280px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    cursor: pointer;
    transition: all 500ms ease-in-out;
    position: relative;
    color: var(--ff);

    .item_store {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .soon {
      position: absolute;
      left: 0;
      background: yellow;
      padding: 5px;
      top: 10px;
      border-radius: 0 5px 5px 0;

      small {
        color: var(--bl);
        font-weight: bold;
      }
    }

    .number_subscribers {
      position: absolute;
      width: 100%;
      top: 25px;
      right: -100px;
      background: red;
      padding: 5px;
      transform: rotate(45deg);
      text-align: center;

      small {
        font-weight: bold;
        font-size: 20px;
      }
    }

    &:hover .add_basket {
      background: var(--ff);
      color: var(--cl);
    }

    &:hover {
      background: var(--cl);
      color: var(--ff);
      border-radius: 0 0 10px 10px;
    }

    &:hover img {
      transform: scale(1.1);
      border-radius: 0 0 30px 30px;
    }

    &:hover .soon {
      background: var(--ff);
    }

    &:hover small {
      padding: 5px;
    }

    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      border-radius: 0 0 10px 10px;
      transition: all 500ms ease-in-out;
    }

    small {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 5px;
      transition: all 300ms ease-in-out;
    }

    small.small_1 {
      font-size: 16px;
      font-weight: bold;
    }

    .submit_delete {
      padding: 10px;

      svg.delete {
        font-size: 20px;
        color: #f00;
        cursor: pointer;
        transition: all 300ms ease-in-out;

        &:hover {
          transform: scale(1.3);
        }
      }
    }
  }
}

.add_basket {
  display: flex;
  gap: 10px;
  font-size: 15px;
  width: fit-content;


}

.direction.direction_rtl .soon {
  left: auto;
  right: 0;
  border-radius: 5px 0 0 5px;
}


.dataPage {
  width: 90%;
  margin: 20px auto;

}

.home {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.date_of_registration {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-width: 2px;
  border-style: groove;
  border-color: var(--bg);
  padding: 5px;
  border-radius: 10px;

  small:nth-child(2) {
    direction: ltr;
  }
}


.box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .alert {
    background-color: #fff;
    font-size: 13px;
    font-family: sans-serif;
    text-align: center;
    width: 280px;
    min-height: 280px;
    position: relative;
    border: 1px solid #efefda;
    border-radius: 2%;
    box-shadow: 0px 0px 3px 1px #ccc;
    padding: 0 10px;

    &::before {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      inset: 20px 0px 0px 100px;
      font-size: 60px;
      line-height: 100px;
      border: 5px solid gray;
      animation-name: reveal;
      animation-duration: 1.5s;
      animation-timing-function: ease-in-out;
    }
  }


  .alert>.alert-body {
    opacity: 0;
    animation-name: reveal-message;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
  }


  .success {
    color: green;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    &::before {
      content: '✓';
      background-color: #eff;
      box-shadow: 0px 0px 12px 7px rgba(200, 255, 150, 0.8) inset;
      border: 5px solid green;
    }
  }


  .error {
    color: red;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    &::before {
      content: '✗';
      background-color: #fef;
      box-shadow: 0px 0px 12px 7px rgba(255, 200, 150, 0.8) inset;
      border: 5px solid red;
    }
  }


}

@keyframes reveal-message {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes reveal {
  0% {
    border: 5px solid transparent;
    color: transparent;
    box-shadow: 0px 0px 12px 7px rgba(255, 250, 250, 0.8) inset;
    transform: rotate(1000deg);
  }

  25% {
    border-top: 5px solid gray;
    color: transparent;
    box-shadow: 0px 0px 17px 10px rgba(255, 250, 250, 0.8) inset;
  }

  50% {
    border-right: 5px solid gray;
    border-left: 5px solid gray;
    color: transparent;
    box-shadow: 0px 0px 17px 10px rgba(200, 200, 200, 0.8) inset;
  }

  75% {
    border-bottom: 5px solid gray;
    color: gray;
    box-shadow: 0px 0px 12px 7px rgba(200, 200, 200, 0.8) inset;
  }

  100% {
    border: 5px solid gray;
    box-shadow: 0px 0px 12px 7px rgba(200, 200, 200, 0.8) inset;
  }
}


.orders {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 25px;
  margin: 20px auto;
  justify-content: center;


  .order {
    width: 100%;
    display: flex;
    gap: 10px;
    overflow: hidden;
    transition: all 500ms ease-in-out;
    position: relative;
    color: var(--ff);
    border-radius: 10px;
    padding: 10px;
    border: 1px solid var(--ff);
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;

    .all_product {
      width: 100%;
      display: flex;
      gap: 10px;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    &:hover {
      transform: translateY(-10px);
      border: 1px solid var(--cl);
    }

    .failed_payment,
    .successful_payment {
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: var(--ff);
      border-radius: 10px;
      padding: 10px;
    }

    .failed_payment {
      background: red;
    }

    .successful_payment {
      background: green;
    }

    .product {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border: 3px solid var(--ff);
      padding: 5px;
      border-radius: 5px;
      max-width: 280px;

      .button {
        width: fit-content;
      }
    }

    p {
      border: 1px solid var(--ff);
      padding: 5px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 5px;

      span {
        border: 1px solid var(--ff);
        padding: 5px;
        border-radius: 5px;
        width: fit-content;
        word-break: break-all;
      }
    }
  }
}


.react-tel-input {
  direction: ltr;

  input.form-control {
    padding-left: 48px;
  }
}

input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}


@media (max-width: 750px) {
  .button {
    font-size: 10px;
  }

  .left_right {
    border-radius: 20px;
    font-size: 80%;
    flex-direction: column;
  }

  .left_right .left a.logo {
    width: 100px;
  }

  .left_right .right form {
    padding: 10px;
  }

}