.form.admin_slider .box {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form.admin_slider form {
    width: 100%;
}

.form.admin_slider .background_upload {
    width: 100% !important;
    height: 350px;
}

.form.admin_slider .div_background_upload {
    width: 120px;
    height: 120px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.div_background_upload input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

.div_background_upload img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


section.section_admin .slider_div {
    margin-top: 50px;
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

section.section_admin .slider_div .slider_item {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 10px;
    border: 1px solid var(--cl);
    padding: 2px;
    border-radius: 5px;
}

section.section_admin .slider_div .slider_item .div_slider_item {
    width: 100%;
    height: 350px;
    overflow: hidden;
}

section.section_admin .slider_div .slider_item svg.delete {
    font-size: 20px;
    color: #f00;
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

section.section_admin .slider_div .slider_item svg.delete:hover {
    opacity: 0.7;
    transform: scale(1.3);
}