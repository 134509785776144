.item_view_championship {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;

    img {
        width: 100%;
        max-width: 300px;
        height: 300px;
        object-fit: cover;
        border-radius: 0 0 10px 10px;
        transition: all 500ms ease-in-out;
    }

    .fixed_information {
        display: flex;
        flex-direction: column;
        gap: 5px;

        small {
            display: flex;
            align-items: center;
            gap: 3px;
        }
    }
}


.full_championship {
    .categories {
        display: flex;
        align-items: center;
        overflow: hidden;
        overflow-x: auto;
        gap: 10px;
        margin-top: 10px;


        .category {
            display: flex;
            align-items: center;
            gap: 5px;
            border: 1px solid var(--cl);
            padding: 5px;
            border-radius: 5px;
            cursor: pointer;
            background: transparent;
            color: var(--ff);

            &:hover {
                opacity: 0.7;
            }

            img {
                width: 35px;
                height: 35px;
                overflow: hidden;
                object-fit: cover;
                border-radius: 50%;
            }
        }

        .active_category {
            background: var(--cl);
        }
    }

    h2 {
        width: fit-content;
        padding: 10px;
        border-radius: 5px;
        background: var(--cl);
        color: var(--ff);
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            top: 50%;
            right: -100%;
            transform: translateY(-50%);
            background: var(--cl);
            z-index: -1;
        }
    }
}

.direction.direction_rtl .full_championship h2::after {
    right: auto;
    left: -100%;
}

.subscription {
    margin: 20px auto;
}