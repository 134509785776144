.home_slider {
    padding: 0 5%;
    direction: ltr;
}

.swiper-horizontal {
    touch-action: pan-y;
    max-height: 400px;
}

.swiper-button-prev,
.swiper-button-next {
    color: var(--ff) !important;
    background: var(--cl);
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
}

.swiper-pagination-bullet-active {
    background: var(--cl) !important;
    transform: scale(1.3);
}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 1rem !important;
    font-weight: bold !important;
}

@media (max-width: 750px) {

    .swiper-button-prev,
    .swiper-button-next {
        width: 25px !important;
        height: 25px !important;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
        font-size: 10px !important;
    }
}