nav {
    position: sticky;
    z-index: 10;
    inset: 0;
    height: 100px;
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
    direction: rtl;

    .right {
        display: flex;
        align-items: center;
        gap: 10px;

        svg {
            font-size: 25px;
            cursor: pointer;
            color: var(--ff);

            &:hover {
                opacity: 0.7;
            }

            @media (max-width:768px) {
                font-size: 20px;
            }
        }

        .pasket_fill {
            position: relative;

            span {
                position: absolute;
                top: -40%;
                right: 50%;
                transform: translateX(50%);
                background: var(--cl);
                padding: 5px;
                border-radius: 50%;
                line-height: 0;
                min-width: 15px;
                min-height: 15px;
                aspect-ratio: 1 / 1;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
            }
        }
    }

    .logo {
        float: left;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 24px;
        color: var(--ff);

        img {
            width: 100px;
            padding: 10px;
        }
    }

    .links {
        float: right;
        padding: 0;
        margin: 0;
        width: max-content;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        li {
            cursor: pointer;
        }

        a {
            display: block;
            padding: 1em;
            font-size: 16px;
            font-weight: bold;
            color: var(--ff);
            text-decoration: none;
            position: relative;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}

#nav-toggle {
    position: absolute;
    top: -100px;
}

nav .icon-burger {
    display: none;
}

nav .icon-burger .line {
    width: 30px;
    height: 5px;
    background: var(--cl);
    margin: 5px;
    border-radius: 3px;
    transition: all .5s ease-in-out;
}

nav .active {
    border-bottom: 5px solid var(--cl);
    color: var(--cl) !important;
}

nav a.logo.active {
    border-bottom: none;
}

nav a.logo {
    cursor: pointer;
}

a.hash.active {
    color: var(--bl) !important;
    border-bottom: none !important;
}

a.button.active {
    color: var(--ff) !important;
}

.full_basket {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    background: #000000c2;
    z-index: 99;
    animation: basket 500ms ease-in-out;

    .basket {
        display: flex;
        justify-content: space-between;
        height: 100%;
        width: 50%;
        max-width: 350px;
        background: var(--ff);
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        padding-top: 40px;
        position: relative;
        color: var(--bl);

        @media (max-width:768px) {
            width: 75%;
        }

        span.total_price {
            background: var(--cl);
            padding: 5px;
        }

        .total_pay {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .total {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            background: var(--bl);
            color: var(--ff);
            direction: ltr;
        }

        svg {
            position: absolute;
            left: 0;
            top: 0;
            color: var(--cl);
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 10px;
            overflow: hidden;
            overflow-y: auto;
            direction: rtl;

            .basket_empty {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 170px;
                text-align: center;
            }

            li {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 100%;
                max-width: 350px;
                background: var(--bl);
                color: var(--ff);
                border-radius: 5px;
                padding: 5px;
                position: relative;

                img {
                    width: 75px;
                    height: 75px;
                    border-radius: 50%;
                }

                .top,
                .botton {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 5px;

                    .count_basket {
                        display: flex;
                        height: 35px;


                        .button {
                            width: 25px;
                            height: 100%;
                            display: flex;
                            padding: 0;
                            align-items: center;
                            justify-content: center;
                            line-height: 0;
                        }

                        .button.button_1 {

                            border-radius: 0 5px 5px 0;
                        }

                        .button.button_2 {
                            border-radius: 5px 0 0 5px;
                        }

                        .item_count_basket {
                            width: 50px;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: var(--ff);
                            color: var(--bl);
                        }
                    }

                    p {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        direction: ltr;
                    }
                }
            }
        }
    }
}


.direction.direction_rtl .full_basket .basket ul li .top p,
.direction.direction_rtl .full_basket .basket ul li .botton p {
    direction: rtl;
}

.direction.direction_ltr .full_basket .total {
    direction: rtl;
}

@keyframes basket {
    0% {
        right: -100%;
    }

    100% {
        right: 0;
    }
}


.payment_pay .overflow_pages {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    .left_right {
        margin: 20px auto;

        @media (max-width:768px) {
            padding: 5px;
        }
    }

    .payment_method {
        display: flex;
        gap: 5px;
        justify-content: space-between;

        label {
            display: flex;
            align-items: center;
            width: 40px;
            position: relative;
            cursor: pointer;
            border-radius: 50%;
            overflow: hidden;
            border: 3px solid transparent;

            input {
                position: absolute;
                z-index: -1;
            }
        }

        label.active_checked {
            border: 3px solid var(--cl);
        }
    }

    .button {
        margin: auto;
        width: 90%;
    }

    .left_right form>div {
        display: flex;
        flex-direction: row;
        gap: 10px;

        @media (max-width:768px) {
            flex-direction: column;
        }

        .right,
        .left {
            flex: 1 1;
            display: flex;
            gap: 1vh;
            color: var(--bl);
            min-width: 280px;
            flex-direction: column;
        }
    }
}

.payment_method.radio label {
    width: fit-content !important;
    padding: 5px;
    border-radius: 5px !important;
    border: 3px solid var(--cl) !important;
}

.payment_method.radio label.active_checked {
    background: var(--cl);
    color: var(--ff);
}

.payment_pay_product form {
    flex-direction: column !important;
}

.payment_method.size {
    label {
        width: fit-content !important;
        border-radius: 0 !important;
        padding: 5px;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    nav {
        flex-direction: row;
        height: 80px;
    }

    nav .logo {
        float: none;
        width: auto;
        justify-content: center;
    }

    nav .links {
        float: none;
        position: fixed;
        z-index: 9;
        left: 0;
        right: 0;
        top: 79px;
        bottom: 100%;
        width: auto;
        height: auto;
        flex-direction: column;
        justify-content: flex-start;
        gap: 1rem;
        background: var(--bl);
        overflow: hidden;
        transition: all .5s ease-in-out;
    }

    nav .links a {
        font-size: 20px;
        color: var(--ff);
        padding: 5px 1em;
    }


    nav :checked~.links {
        bottom: 0;
    }

    nav .icon-burger {
        display: block;
    }

    nav :checked~.icon-burger .line:nth-child(1) {
        transform: translateY(10px) rotate(225deg);
    }

    nav :checked~.icon-burger .line:nth-child(3) {
        transform: translateY(-10px) rotate(-225deg);
    }

    nav :checked~.icon-burger .line:nth-child(2) {
        opacity: 0;
    }

    nav .logo img {
        width: 60px;
    }
}