.form {
    display: flex;
    flex-direction: column;
    gap: 7px;
    position: relative;
    color: var(--ff);
    width: 100%;
    height: 100%;
    min-height: 50vh;
    align-items: center;
    justify-content: center;
}

.form form {
    background: var(--cl);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 20px;
}

.form form .input {
    padding: 1rem;
    border-radius: 1rem;
    background: #e8e8e8;
    transition: 0.3s;
}

.form form .input:focus {
    outline-color: #e8e8e8;
    background: #e8e8e8;
    transition: 0.3s;
}

section.section_admin .social_media_links {
    margin-top: 50px;
}

section.section_admin .social_media_links .social_item {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 10px;
    border: 1px solid var(--cl);
    padding: 2px;
    border-radius: 5px;
}

section.section_admin .social_media_links .social_item svg.delete {
    font-size: 20px;
    color: #f00;
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

section.section_admin .social_media_links .social_item svg.delete:hover {
    opacity: 0.7;
    transform: scale(1.3);
}