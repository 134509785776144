.form.add_championship {

    .info_required_form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        border: 1px solid var(--ff);
        padding: 10px;
        border-radius: 10px;
        max-width: 500px;
        margin: 20px auto;

        .required_form {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            justify-content: center;
        }

        .button {
            background: var(--ff);
            color: var(--cl);
            width: fit-content;
        }

        .delete_questions {
            color: #f00;
            animation: delete_questions 1s ease-in-out infinite alternate;
        }

        .inputBox {
            svg {
                font-size: 20px;
                color: #f00;
                cursor: pointer;
                transition: all 300ms ease-in-out;

                &:hover {
                    transform: scale(1.2);
                }
            }
        }
    }

    .inputBox {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        width: fit-content;

        .input {
            flex: 1;
        }
    }

    .price_currency {
        display: flex;
        align-items: center;
        gap: 5px;
        display: flex;
        align-items: center;
        gap: 5px;
        border: 1px solid var(--ff);
        padding: 5px;
        border-radius: 10px;

        .input {
            width: 100px;
            text-align: center;
        }

    }

    .background_upload {
        width: 150px;
        height: 150px;
    }

    fieldset {
        border: 2px solid var(--ff);
        width: fit-content;
    }

    legend {
        padding: 0;
    }
}

.div_position_fixed.form.add_championship.update_championship {
    justify-content: flex-start;
    padding: 20px 0;

    fieldset {
        border: 2px solid var(--ff);
        width: fit-content;
    }

    legend {
        padding: 0;
    }
}

@keyframes delete_questions {
    0% {
        opacity: 0.5;
        font-weight: 100;
    }

    100% {
        opacity: 1;
        font-weight: bold;
    }
}