.pages.support {
    .support_div {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 20px auto;
        margin-top: 0;

        a {
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 2px;
            background: var(--cl);
            color: var(--ff);
            padding: 5px 10px;
            border-radius: 10px;

            span {
                direction: ltr;
            }

            @media (max-width:768px) {
                font-size: 10px;
            }

            svg {
                font-size: 20px;
            }
        }
    }
}