.form.add_store {
    .price_currency {
        display: flex;
        align-items: center;
        gap: 5px;
        display: flex;
        align-items: center;
        gap: 5px;
        border: 1px solid var(--ff);
        padding: 5px;
        border-radius: 10px;

        .input {
            width: 100px;
            text-align: center;
        }

    }

    .background_upload {
        width: 150px;
        height: 150px;
    }

    fieldset {
        border: 2px solid var(--ff);
    }

    legend {
        padding: 0;
    }
}

.div_position_fixed.form.add_store.update_store {
    justify-content: flex-start;
    padding: 20px 0;

    fieldset {
        border: 2px solid var(--ff);
    }

    legend {
        padding: 0;
    }
}