div#footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: auto;
    padding: 20px 0;
    border-top: 3px solid var(--ff);
    flex-wrap: wrap;
    gap: 2vh;
    direction: rtl;
    color: var(--ff);
}

div#footer div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    flex: 1;
    min-width: 200px;
    font-size: 80%;
    text-align: center;
}

div#footer div:nth-child(1) {
    justify-content: flex-start;
}

div#footer div:nth-child(3) {
    justify-content: flex-end;
}

div#footer div a.logo {
    display: block;
    width: 100px;
    padding: 10px;
}

div#footer div a {
    color: var(--ff);
    display: flex;
    align-items: center;
    gap: 2px;
}

div#footer a.active {
    color: var(--cl);
    font-weight: bold;
}

div#footer .center {
    flex-direction: column;
}

@media (max-width: 768px) {
    div#footer div {
        justify-content: center !important;
    }
}